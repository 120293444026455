<template>
    <p>数据更新时间：{{synchronizeTime}}</p>
</template>

<script>
	import util from '@/utils/util'
	import table from '@/api/table'
	import {mapState} from 'vuex'

	export default {
		name:"time-select",
		data(){
			return {
				synchronizeTime:'未知'
			}
		},
		computed:{
			...mapState([
				'authorizerId',
				'shopNo',
				'openid',
			]),
		},
		methods:{
			/**
             * 获取同步时间
			 */
			getSynchronizeTime(){
				table.getSynchronizeTime().then(res =>{
					this.synchronizeTime = util.formatDate(res.data.synchronize_time,'yyyy年MM月dd日 hh:mm:ss')
				}).catch(err =>{
					console.log(err.info)
				})
			},
		},
		mounted(){
			this.getSynchronizeTime()
		},
	}
</script>

<style lang="scss" type="text/scss" rel="stylesheet/scss" scoped>
    @import "~@/assets/style/public.scss";
    p{
        font-size:22px;
        line-height:60px;
        background:#FFE3E0;
        text-align:center;
        margin:18px 0 0 0;
    }
</style>
